import { Dialog } from "primereact/dialog";
import { ButtonHTMLAttributes, useContext, useState } from "react";
import styles from "./styles.module.css";
import { LoginSocialFacebook } from "reactjs-social-login";
import { InstagramLoginButton } from "react-social-login-buttons";
import { FuncoesModalAvisoOptin } from "./functions";
import { RenovarTokenContext } from "../../../context/RenovarTokenContext";
import { ErroType } from "../../../@types/erro";

export interface AvisoOptInProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: any) => void;
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
}





export const AvisoOptIn = ({
  modalAberto,
  setModalAberto,
}: AvisoOptInProps) => {
    const [carregando, setCarregando] = useState<boolean>(false);
    const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
    const [tokenAcesso, setTokenAcesso] = useState<string>("");
    const [appId, setAppId] = useState<string>("");
    const { adicionarAListaEsperaRenovacaoToken } = useContext(RenovarTokenContext);

    const { tokenLongaDuracao } = FuncoesModalAvisoOptin({
      tokenAcesso,
      appId,
      setCarregando,
      setErro,
      adicionarAListaEsperaRenovacaoToken,
    });


  return (
    <div className={styles.logoRedesSociais}>
      <Dialog
        header={<h1>Informações Opt In</h1>}
        className={styles.modalBase}
        visible={modalAberto}
        onHide={() => setModalAberto(false)}
        dismissableMask
        draggable
        modal
        blockScroll
        keepInViewport
        focusOnShow
      >
        <h2>
          Os dados pessoais coletados serão usados para prestação dos nossos
          serviços como, a geração de relatórios analíticos de desempenho. Os
          seus dados serão compartilhados somente com os profissionais
          envolvidos, da NetCos, da agência e anunciante contratantes. Se quiser
          saber mais, acesse a nossa{" "}
          <a
            href="https://d3encfp7yw6we0.cloudfront.net/DocumentosLegais/PoliticaDePrivacidade_22eb22bc-6625-46be-b068-2c2eb9ea6c48.pdf"
            target="_blank"
          >
            Política de Privacidade.
          </a>
        </h2>
        <br />
        <h2>
          Para que a ferramenta funcione plenamente, você precisa ter{" "}
          <strong>uma página no Facebook conectada à sua conta</strong>. Isso
          significa que a sua página do Facebook tem que estar vinculada ao seu
          perfil pessoal no Facebook e à sua conta do Instagram também.
          <br /><br />
          Basicamente, você precisa:
          <li>
            <strong>Ter uma página no Facebook:</strong> Não pode ser só um
            perfil pessoal, tem que ser uma página que você gerencia.{" "}
          </li>
          <br />
          <li>
            <strong>Vincular sua página à sua conta do Facebook:</strong> A
            página precisa estar conectada à sua conta do Facebook para que você
            tenha acesso aos dados dela. Isso é importante para poder pegar
            informações como curtidas, visualizações e outras estatísticas.
          </li>
          <br />
          <li>
            <strong> Conectar o Instagram:</strong> Se você também quer métricas
            do Instagram, precisa conectar sua conta do Instagram à página do
            Facebook. Isso é feito nas configurações da página no Facebook.
          </li>
        </h2>
        <div className="botaoOptin">
          <LoginSocialFacebook
            appId="741129074259476"
            scope="
                      instagram_basic,
                      instagram_manage_insights,
                      pages_show_list,
                      public_profile,
                      business_management,
                      read_insights, 
                      "
            fieldsProfile="id,
                        first_name,
                        last_name,
                        middle_name,
                        name,
                        name_format,
                        picture,
                        short_name,
                        email,
                        gender,
                        permissions
                        "
            onResolve={(response: any) => {
              const accessToken = response.data!.accessToken;
              const userID = response.data!.userID;
              localStorage.setItem("accessToken", accessToken);
              localStorage.setItem("userID", userID);
              tokenLongaDuracao();
            }}
            onReject={(err: any) => {
              console.log(err);
            }}
          >
            <InstagramLoginButton
              text="Conceder o Opt-in"
              style={{
                marginTop:'25px',
                marginLeft:'auto',
                marginRight: 'auto',
                display: "flex",
                justifyContent: "center",
                borderRadius: 25,
                width: 170,
                height: 40,
                fontSize: 14,
              }}
            />
          </LoginSocialFacebook>
        </div>
      </Dialog>
    </div>
  );
};
