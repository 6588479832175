import React, { useContext, useRef, useState } from "react";

import styles from "./styles.module.css";

import { FunctionsModalCadastroInfluenciador } from "./functions";

import { Button } from "../../Botoes/Button";
import { Carregando } from "../../Carregando";
import { ModalTeste } from "../ModalTeste";
import { Input } from "../../Input";
import { SelectPerfilInfluenciador } from "../../Select/SelectPerfilInfluenciador";
import { BotaoUploadImagem } from "../../Botoes/BotaoUploadImagem";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { ErroType } from "../../../@types/erro";
import { LimparStates } from "../../../utils/LimparStates";

import { Toast } from "primereact/toast";

import { RenovarTokenContext } from "../../../context/RenovarTokenContext";
import { PhoneInput } from "react-international-phone";

import { MinLengthValidation } from "../../../validation/validators/min-length-validation";
import { SelectFieldValidation } from "../../../validation/validators";

interface ModalCadastroInfluenciador {
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
  setRecarregar?: React.Dispatch<React.SetStateAction<boolean>>;
  setIdInfluenciador?: React.Dispatch<React.SetStateAction<string>>;
  setEmailInfluenciador?: React.Dispatch<React.SetStateAction<string>>;
  setNomeInfluenciador?: React.Dispatch<React.SetStateAction<string>>;
  setPerfilInfluenciador?: React.Dispatch<React.SetStateAction<string>>;
  nomeInput?: string;
  nomeInfluenciador?: string;
  emailInfluenciador?: string;
  perfilInfluenciador?: string;
}

export const ModalCadastroInfluenciador = ({
  modalAberto,
  setModalAberto,
  setRecarregar,
  setIdInfluenciador,
  setNomeInfluenciador,
  setEmailInfluenciador,
  setPerfilInfluenciador,
  nomeInput,
  nomeInfluenciador,
  emailInfluenciador,
  perfilInfluenciador,
}: ModalCadastroInfluenciador) => {
  const [nome, setNome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [perfil, setPerfil] = useState<string>("");
  const [imagemAvatar, setImagemAvatar] = useState<string>("");
  const [telefone, setTelefone] = useState<number>(0);
  const [enviarNotificacaoDeCadastro, setEnviarNotificacaoDeCadastro] = useState<boolean>(false);
  const [carregando, setCarregando] = useState<boolean>(false);
  const [MascaraNumeroWhatsapp, setMascaraNumeroWhatsapp] = useState<string>("");
  const [numeroPagina, setNumeroPagina] = useState(1);
  const [tamanhoPagina, setTamanhoPagina] = useState(2000);
  const [tipoOrdenacao, setTipoOrdenacao] = useState<tipoOrdenacao>("Ascending");
  const [nomePropriedadeOrdenacao, setNomePropriedadeOrdenacao] = useState<string>("Nome");
  const [erro, setErro] = useState<ErroType>({ ativo: false, mensagem: "" });
  const nomeMinLengthValidator = new MinLengthValidation("nome", 3);
  const emailMinLengthValidator = new MinLengthValidation("email", 3);
  const selectPerfilValidator = new SelectFieldValidation("perfilInfluenciador");
  const [validationErrors, setValidationErrors] = useState<Record<string, string | null>>({});
  const { adicionarAListaEsperaRenovacaoToken } = useContext(RenovarTokenContext);
  const myToast = useRef<any>(null);

  const handleFormSubmit = (nome: string, email: string, nomeInput : string) => {
    const nomeToValidate = nome || nomeInput;
    const nomeError = nomeMinLengthValidator.validate({ nome: nomeToValidate });
    const emailError = emailMinLengthValidator.validate({ email: email });
    // const selectPerfilError = selectPerfilValidator.validate({perfilInfluenciador: perfilInfluenciador});

    setValidationErrors({
      nome: nomeError ? "O campo nome é obrigatório" : null,
      email: emailError ? "O campo email é obrigatório" : null,
      // perfil: selectPerfilError ? "Por favor, selecione uma perfil para o influenciador" : null,
    });

    if (!nomeError && !emailError) {
      salvarInfluenciador();
    }
  };

  const { salvarInfluenciador } = FunctionsModalCadastroInfluenciador({
    nomeInfluenciador: nomeInfluenciador === "" ? nomeInput : nomeInfluenciador,
    nome,
    emailInfluenciador: emailInfluenciador ?? email,
    perfilInfluenciador: perfilInfluenciador ?? perfil,
    imagemAvatar,
    setImagemAvatar,
    telefone,
    setTelefone,
    enviarNotificacaoDeCadastro,
    setEnviarNotificacaoDeCadastro,
    myToast,
    MascaraNumeroWhatsapp,
    setMascaraNumeroWhatsapp,
    setModalAberto,
    setCarregando,
    adicionarAListaEsperaRenovacaoToken,
    setRecarregar,
    setIdInfluenciador,
    setNomeInfluenciador: setNomeInfluenciador ?? setNome,
    setEmailInfluenciador: setEmailInfluenciador ?? setEmail,
    setPerfilInfluenciador: setPerfilInfluenciador ?? setPerfil,
    nomePropriedadeOrdenacao,
    numeroPagina,
    tamanhoPagina,
    tipoOrdenacao,
    setErro,
  });

  return (
    <ModalTeste
      modalAberto={modalAberto}
      setModalAberto={setModalAberto}
      titulo={"Cadastro de Influenciador"}
    >
      <div className={styles.conteudoModalCadastroInfluenciador}>
        <div>
          <BotaoUploadImagem setImg={setImagemAvatar} />
          <div className={styles.containerDados}>
            <div className={styles.containerInputNomeEmail}>
              {setNomeInfluenciador ? (
                <Input
                  placeholder="Nome"
                  value={
                    nomeInfluenciador === "" ? nomeInput : nomeInfluenciador
                  }
                  onChange={(e) => setNomeInfluenciador(e.target.value)}
                />
          
              ) : (
                <Input
                  id="nome"
                  placeholder="Nome"
                  value={nome === "" ? nomeInput : nome}
                  onChange={(e) => {
                    setNome(e.target.value);
                    console.log(nome);
                  }}
                />
                
              )}
              {validationErrors.nome || validationErrors.nomeInput && (
                <small className={styles.mensagemValidacao}>
                  {validationErrors.nome}
                </small>
              )}
              <Input
                placeholder="E-mail"
                id="emailValid"
                value={emailInfluenciador ?? email}
                onChange={(e) => {
                  setEmailInfluenciador
                    ? setEmailInfluenciador(e.target.value)
                    : setEmail(e.target.value);
                }}
              />
            </div>
            {validationErrors.email && (
              <small className={styles.mensagemValidacao}>
                {validationErrors.email}
              </small>
            )}

            <div className={styles.containerInputs}>
              <PhoneInput
                name="telefone"
                defaultCountry="br"
                forceDialCode
                preferredCountries={["br", "us"]}
                placeholder="Telefone"
                value={MascaraNumeroWhatsapp}
                onChange={(phone, country) => {
                  setMascaraNumeroWhatsapp(country.inputValue);
                }}
              />

              <SelectPerfilInfluenciador
                setPerfil={setPerfilInfluenciador ?? setPerfil}
                perfil={perfilInfluenciador ?? perfil}
              />
              {/* {validationErrors.perfil && (
                <small className={styles.mensagemValidacao}>
                  {validationErrors.perfil}
                </small>
              )} */}
            </div>

            <div className={styles.containerInputCheckbox}>
              <input
                id="enviar-email"
                type="checkbox"
                onClick={() => {
                  setEnviarNotificacaoDeCadastro(!enviarNotificacaoDeCadastro);
                }}
              />
              <label className={styles.labelCheckBox} htmlFor="enviar-email">
                Enviar email de cadastro para o usuário?
              </label>
            </div>
          </div>
          <footer>
            {carregando ? (
              <Carregando />
            ) : (
              <div className={styles.containerBotoesModal}>
                <Button
                  importancia="terciario"
                  type="button"
                  title="Cancelar"
                  onClick={() => {
                    setModalAberto(false);
                    if (
                      setEmailInfluenciador &&
                      setPerfilInfluenciador &&
                      setNomeInfluenciador
                    ) {
                      LimparStates([
                        setEmailInfluenciador,
                        setPerfilInfluenciador,
                        setNomeInfluenciador,
                        setMascaraNumeroWhatsapp,
                      ]);
                    }
                    setTelefone(0);
                    LimparStates([
                      setEmail,
                      setPerfil,
                      setNome,
                      setMascaraNumeroWhatsapp,
                    ]);
                  }}
                />
                <Button
                  importancia="primario"
                  title="Confirmar"
                  type="submit"
                  onClick={() => {handleFormSubmit(nome, email, nomeInput!, )}}
                />
              </div>
            )}
          </footer>
        </div>
      </div>

      <Toast ref={myToast} className="custom-toast" />
    </ModalTeste>
  );
};
