import axios from "axios";
import React, { useContext } from "react";
import { MostrarToastContext } from "../../../context/MostrarToast";

export interface Post {
  id: string;
  shortcode: string;
}
export interface Story {
  id: string;
  permalink: string;
  timestamp: string;
}

interface pagesProps {
  instagram_business_account: InstagramBusinessAccount;
  id: string;
}
interface InstagramBusinessAccount {
  id: string;
  name: string;
  username: string;
}

export interface FunctionsOptInProps {
  impressoes: number;
  setImpressoes: React.Dispatch<React.SetStateAction<number>>;
  alcance: number;
  setAlcance: React.Dispatch<React.SetStateAction<number>>;
  compartilhamentos: number;
  setCompartilhamentos: React.Dispatch<React.SetStateAction<number>>;
  engajamentos: number;
  setEngajamentos: React.Dispatch<React.SetStateAction<number>>;
  visitasAoPerfil: number;
  setVisitasAoPerfil: React.Dispatch<React.SetStateAction<number>>;
  setSalvos: React.Dispatch<React.SetStateAction<number>>;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  setPlays: React.Dispatch<React.SetStateAction<number>>;
  setViews: React.Dispatch<React.SetStateAction<number>>;
  setRespostas: React.Dispatch<React.SetStateAction<number>>;
  setDataInclusaoStories: React.Dispatch<React.SetStateAction<string>>;
  setStatesOptinPreenchidas: React.Dispatch<React.SetStateAction<boolean>>;
  setQuantidadeReproducoes: React.Dispatch<React.SetStateAction<number>>;
}

interface pegaPaginasDoUsuarioProps {
  urlPublicacao: string;
  userIdFacebook: string;
  tokenLongaDuracao: string;
}

export const FunctionsOptIn = ({
  impressoes,
  setImpressoes,
  alcance,
  setAlcance,
  compartilhamentos,
  setCompartilhamentos,
  engajamentos,
  setEngajamentos,
  visitasAoPerfil,
  setVisitasAoPerfil,
  setComentarios,
  setLikes,
  setPlays,
  setSalvos,
  setViews,
  setRespostas,
  setDataInclusaoStories,
  setStatesOptinPreenchidas,
  setQuantidadeReproducoes,
}: FunctionsOptInProps) => {
  
  const { mostrarToast } = useContext(MostrarToastContext);
  let postUrl: any;

  const showError = (type: 'url' | 'metric' | 'idBusiness' | 'mediaEmpty' | 'storiesExpired') => {
    const messages = {
      url: { title: 'URL inválido', message: 'Verifique as informações' },
      metric: { title: 'Erro ao obter métricas', message: 'Tente novamente mais tarde' },
      idBusiness: { title: 'Conta não encontrada', message: 'Verifique se sua conta está vinculada' },
      mediaEmpty: { title: 'Publicação não encontrada', message: 'Verifique a publicação no Instagram' },
      storiesExpired: { title: 'Story expirado', message: 'Não é possível obter um story fora do ar' }
    };

    const error = messages[type];
    mostrarToast('error', error.title, error.message);
  };

  const fetchData = async (url: string): Promise<any> => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return null;
    }
  };
  const pegarMetricas = async (
    tokenLongaDuracao: string,
    tipoPublicacao: string,
    urlPublicacao: string,
    userIdFacebook: string
  ) => {
    let pages = await pegaPaginasDoUsuario({
      tokenLongaDuracao : localStorage.getItem('access_token') ?? '',
      urlPublicacao,
      userIdFacebook,
    });

    console.log('token longa duracao na function do opt in', tokenLongaDuracao);
    
  
    if (pages && pages.length > 0) {
    
      if (tipoPublicacao === "PublicacaoInstagramStories") {
        const medias = await getMediaByType(tipoPublicacao, pages, tokenLongaDuracao) as Story[]; 
  
        if (medias.length > 0) {
          CompararPermalinkStories(tokenLongaDuracao, medias);
        } else {
          showError('mediaEmpty');
        }
      } else {
        const medias = await getMediaByType(tipoPublicacao, pages, tokenLongaDuracao) as Post[];  // Garantimos que é Post[]
  
        if (medias.length > 0) {
          switch (tipoPublicacao) {
            case "PublicacaoInstagramFeed":
              CompararShortcode(tokenLongaDuracao, medias);
              break;
            case "PublicacaoInstagramReels":
              CompararShortcodeReels(tokenLongaDuracao, medias);
              break;
            case "PublicacaoInstagramAlbum":
              CompararShortcodeAlbum(tokenLongaDuracao, medias);
              break;
            default:
              showError('url');
          }
        } else {
          showError('mediaEmpty');
        }
      }
    } else {
      showError('idBusiness');
    }
  };


  async function pegaPaginasDoUsuario({
    urlPublicacao,
    tokenLongaDuracao,
    userIdFacebook,
  }: pegaPaginasDoUsuarioProps): Promise<pagesProps[]> {
    const baseURLIdInstagramBusiness = `https://graph.facebook.com/v18.0/${userIdFacebook}/accounts?fields=id%2Cname%2Caccess_token%2Cinstagram_business_account&access_token=${tokenLongaDuracao}`;

    const data = await fetchData(baseURLIdInstagramBusiness);
    if (data) {
      postUrl = urlPublicacao;
      return data.data;
    } else {
      showError('url');
      return [];
    }
  }

  const getMediaByType = async (tipoPublicacao: string, pages: pagesProps[], tokenLongaDuracao: string): Promise<Story[] | Post[]> => {
    if (tipoPublicacao === "PublicacaoInstagramStories") {
      let medias: Story[] = [];
      for (const pagina of pages) {
        const mediasProvisorias = await pegarStories(pagina.instagram_business_account.id, tokenLongaDuracao);
        medias.push(...mediasProvisorias);
      }
      return medias;
    } else {
      let medias: Post[] = [];
      const mediaMap: { [key: string]: (id: string, token: string) => Promise<Post[]> } = {
        PublicacaoInstagramFeed: pegarMediaId,
        PublicacaoInstagramReels: pegarMediaIdReels,
        PublicacaoInstagramAlbum: pegarMediaIdAlbum,
      };
  
      if (!mediaMap[tipoPublicacao]) {
        showError('url');
        return [];
      }
  
      for (const pagina of pages) {
        const mediasProvisorias = await mediaMap[tipoPublicacao](pagina.instagram_business_account.id, tokenLongaDuracao);
        medias.push(...mediasProvisorias);
      }
  
      return medias;
    }
  };
  
  

  const CompararShortcode = async (tokenLongaDuracao: string, postArray: Post[]) => {
    let postShortcode: Post = { id: "", shortcode: "" };
    let mostrarErro = true;

    for (const post of postArray) {
      if (postUrl.includes(post.shortcode)) {
        postShortcode = post;
        mostrarErro = false;
        pegarMetricasFeed(postShortcode, tokenLongaDuracao);
        break;
      }
    }

    if (mostrarErro) {
      showError('url');
    }
  };

  const CompararShortcodeReels = (tokenLongaDuracao: string, postArray: Post[]) => {
    let postShortcode: Post = { id: "", shortcode: "" };
    let mostrarErro = true;

    postArray.map((post) => {
      if (postUrl.includes(post.shortcode)) {
        mostrarErro = false;
        postShortcode = post;
        pegarMetricasReels(postShortcode, tokenLongaDuracao);
      }
    });

    if (mostrarErro) {
      showError('url');
    }
  };

  const CompararShortcodeAlbum = (tokenLongaDuracao: string, postArray: Post[]) => {
    let postShortcode: Post = { id: "", shortcode: "" };
    let mostrarErro = true;

    postArray.map((post) => {
      if (postUrl.includes(post.shortcode)) {
        mostrarErro = false;
        postShortcode = post;
        pegarMetricasAlbum(postShortcode, tokenLongaDuracao);
      }
    });

    if (mostrarErro) {
      showError('url');
    }
  };

  const CompararPermalinkStories = (tokenLongaDuracao: string, storiesArray: Story[]) => {
    let storyLink: Story = { permalink: "", id: "", timestamp: "" };
    let mostrarErro = true;

    for (const story of storiesArray) {
      let permalinkCode = story.permalink.split("/")[5];
      if (postUrl.includes(permalinkCode)) {
        storyLink = story;
        mostrarErro = false;
        setDataInclusaoStories(story.timestamp);
        pegarMetricasStories(storyLink.id, tokenLongaDuracao);
        break;
      }
    }

    if (mostrarErro) {
      showError('url');
    }
  };

  // #region PEGAR MÍDIA
  //pegar mídia no Instagram Feed
  const pegarMediaId = async (idInstagramBusiness: string, tokenLongaDuracao: string): Promise<Post[]> => {
    const baseURLMediaId = `https://graph.facebook.com/v18.0/${idInstagramBusiness}/media?fields=shortcode&access_token=${tokenLongaDuracao}`;
    const data = await fetchData(baseURLMediaId);
    return data ? data.data : [];
  };

  //pegar mídia no Instagram Reels
  const pegarMediaIdReels = async (idInstagramBusiness: string, tokenLongaDuracao: string): Promise<Post[]> => {
    const baseURLReelsId = `https://graph.facebook.com/v18.0/${idInstagramBusiness}/media?fields=shortcode&access_token=${tokenLongaDuracao}`;
    const data = await fetchData(baseURLReelsId);
    return data ? data.data : [];
  };

  // pegar mídia no Instagram Album
  const pegarMediaIdAlbum = async (idInstagramBusiness: string, tokenLongaDuracao: string): Promise<Post[]> => {
    const baseURLAlbumId = `https://graph.facebook.com/v18.0/${idInstagramBusiness}/media?fields=shortcode&access_token=${tokenLongaDuracao}`;
    const data = await fetchData(baseURLAlbumId);
    return data ? data.data : [];
  };

  //pegar stories do Instagram
  const pegarStories = async (idInstagramBusiness: string, tokenLongaDuracao: string): Promise<Story[]> => {
    const baseURLStories = `https://graph.facebook.com/v18.0/${idInstagramBusiness}/stories?fields=permalink,timestamp&access_token=${tokenLongaDuracao}`;
    const data = await fetchData(baseURLStories);
    if (data) {
      return data.data;
    } else {
      showError('url');
      setStatesOptinPreenchidas(false);
      return [];
    }
  };


  // #region PEGAR MÉTRICAS
  //pegar métricas do Instagram Feed
  const pegarMetricasFeed = async (post: Post, tokenLongaDuracao: string) => {
    const urlMetricas = `https://graph.facebook.com/v18.0/${post.id}/insights?metric=impressions,reach,saved,engagement,shares,profile_visits&access_token=${tokenLongaDuracao}`;
    const data = await fetchData(urlMetricas);
    if (data) {
      setImpressoes(data.data[0].values[0].value);
      setAlcance(data.data[1].values[0].value);
      setSalvos(data.data[2].values[0].value);
      setEngajamentos(data.data[3].values[0].value);
      setCompartilhamentos(data.data[4].values[0].value);
      setVisitasAoPerfil(data.data[5].values[0].value);
      setStatesOptinPreenchidas(true);
    } else {
      showError('metric');
    }
  };

  //pegar métricas do Instagram Reels
  const pegarMetricasReels = async (post: Post, tokenLongaDuracao: string) => {
    const urlMetricas = `https://graph.facebook.com/v18.0/${post.id}/insights?metric=plays,reach,comments,likes,saved,shares&access_token=${tokenLongaDuracao}`;
    const data = await fetchData(urlMetricas);
    if (data) {
      setPlays(data.data[0].values[0].value);
      setAlcance(data.data[1].values[0].value);
      setComentarios(data.data[2].values[0].value);
      setLikes(data.data[3].values[0].value);
      setSalvos(data.data[4].values[0].value);
      setCompartilhamentos(data.data[5].values[0].value);
      setStatesOptinPreenchidas(true);
    } else {
      showError('metric');
    }
  };

  //pegar métricas do Instagram Album
  const pegarMetricasAlbum = async (post: Post, tokenLongaDuracao: string) => {
    const urlMetricas = `https://graph.facebook.com/v18.0/${post.id}/insights?metric=carousel_album_impressions,carousel_album_reach,carousel_album_engagement,carousel_album_saved,carousel_album_shares&access_token=${tokenLongaDuracao}`;
    const data = await fetchData(urlMetricas);
    if (data) {
      setImpressoes(data.data[0].values[0].value);
      setAlcance(data.data[1].values[0].value);
      setEngajamentos(data.data[2].values[0].value);
      setSalvos(data.data[3].values[0].value);
      setCompartilhamentos(data.data[4].values[0].value);
      setStatesOptinPreenchidas(true);
    } else {
      showError('metric');
    }
  };

  //pegar métricas dos Stories
  const pegarMetricasStories = async (idStories: string, tokenLongaDuracao: string) => {
    const urlMetricas = `https://graph.facebook.com/v18.0/${idStories}/insights?metric=impressions,reach,replies,taps_forward,taps_back,shares,profile_visits&access_token=${tokenLongaDuracao}`;
    const data = await fetchData(urlMetricas);
    if (data) {
      setImpressoes(data.data[0].values[0].value);
      setAlcance(data.data[1].values[0].value);
      setRespostas(data.data[2].values[0].value);
      setViews(data.data[3].values[0].value);
      setCompartilhamentos(data.data[4].values[0].value);
      setVisitasAoPerfil(data.data[5].values[0].value);
      setStatesOptinPreenchidas(true);
    } else {
      showError('metric');
    }
  };

  return{
    pegarMetricas
  }
};

